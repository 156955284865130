<template>
    <div>
        <div v-if="survey">
            <SurveyContent ref="surveyConent" :survey="survey" btnColor="#49BCC6" :cachePrefix="ownerUserId"
                           :surveyDataId="surveyDataId" @onSaved="saveSurvey" :showPhoneBtn="true" :ownerUserId="ownerUserId"
                           @make-phone-call="makePhoneCall" :patient-id="patientId">
            </SurveyContent>
        </div>
        <el-dialog
                title="电话"
                :visible.sync="dialogVisible"
                width="30%"
                :show-close="false">
            <span>{{ dialogContent }}</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <SurveyPreviewDialog v-if="survey" :show.sync="showPreview" :survey="survey" @confirm="previewConfirm"></SurveyPreviewDialog>
    </div>
</template>

<script>
import SurveyContent from "@/components/survey/components/SurveyContent";
import SurveyPreviewDialog from "@/components/survey/components/SurveyPreviewDialog";

export default {
    name: "TaskSurvey",
    data() {
        return {
            surveyId: undefined,
            survey: undefined,
            surveyDataId: undefined,
            taskId: undefined,
            ownerUserId: undefined,
            dialogContent: '',
            dialogVisible: false,
            showPreview: false,
            surveyDataBeforeConfirm: undefined, //预览之前存放数据
            patientId: undefined
        }
    },
    components: {
        SurveyPreviewDialog,
        SurveyContent
    },
    created() {
        const surveyId = this.$route.query.survey
        const taskId = this.$route.query.taskId
        if (!surveyId || !taskId) {
            this.$message.error('参数错误')
            return
        }
        this.surveyId = surveyId
        this.taskId = taskId
        this.surveyDataId = this.$route.query['survey-data']
        this.ownerUserId = this.$route.query.ownerUserId
        this.patientId = this.$route.query.patientId
        this.fetchSurvey()
    },
    methods: {
        fetchSurvey() {
            this.$httpUtil.post(this.$urlConstant.survey.surveyGetPost, this.$route.query,res => {
                this.survey = res.data
            },this)
        },
        doSaveSurvey(surveyData) {
            surveyData.taskId = this.taskId
            if (this.ownerUserId) {
                surveyData.ownerUserId = this.ownerUserId
            }
            if (this.surveyDataId) {
                this.$httpUtil.put(this.$urlConstant.survey.surveyDataGetPut + this.surveyDataId, surveyData, () => {
                    this.doSuccess()
                }, this)
            } else {
                this.$httpUtil.post(this.$urlConstant.survey.surveyDataWithTaskPost, surveyData, () => {
                    this.$refs.surveyConent.cleanCachedData()
                    this.doSuccess()
                }, this)
            }
        },
        saveSurvey(data) {
            console.log(this.survey.previewBeforeSave)
            const surveyData = data.surveyData
            // const survey = data.survey
            if (this.survey.previewBeforeSave) {
                this.surveyDataBeforeConfirm = surveyData
                this.showPreview = true
            }else {
                this.doSaveSurvey(surveyData)
            }
        },
        previewConfirm() {
          this.doSaveSurvey(this.surveyDataBeforeConfirm)
        },
        doSuccess() {
            this.$router.replace({name: 'TaskList'})
        },
        makePhoneCall() {
            this.$httpUtil.get(this.$urlConstant.cms.userByIdsGet + this.ownerUserId, res => {
                if (res.data && res.data.length > 0) {
                    const user = res.data[0]
                    console.log(user)
                    this.dialogContent = user.username
                    this.dialogVisible = true
                } else {
                    this.$message.error('用户不存在')
                }
            }, this)
        }
    }
}
</script>

<style scoped>

</style>